<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} user</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="4">
              <v-text-field 
                outlined 
                dense 
                :error="$v.admin_user.first_name.$error"
                v-model="admin_user.first_name"
              >
                <template v-slot:label>
                  First name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.first_name" >{{errors.first_name[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.first_name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field 
                outlined 
                dense 
                :error="$v.admin_user.middle_name.$error"
                v-model="admin_user.middle_name"
              >
                <template v-slot:label>
                  Middle name
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.middle_name" >{{errors.middle_name[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.middle_name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field 
                outlined 
                dense 
                :error="$v.admin_user.last_name.$error"
                v-model="admin_user.last_name"
              >
                <template v-slot:label>
                  Surname <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.last_name" >{{errors.last_name[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.last_name.$error">This information is required</span>
            </v-col>



            <v-col cols="12" md="6">
              <v-text-field 
                outlined 
                dense 
                :error="$v.admin_user.username.$error"
                v-model="admin_user.username"
              >
                <template v-slot:label>
                  Username <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.username" >{{errors.username[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.username.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                outlined 
                dense 
                type="email"
                :error="$v.admin_user.email.$error"
                v-model="admin_user.email"
              >
                <template v-slot:label>
                  Email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.email" >{{errors.email[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.email.$error">Invalid email</span>
            </v-col>

            <v-col cols="12" md="6" v-if="! this.edit"> 
              <v-text-field 
                outlined 
                type="password"
                dense 
                :error="$v.admin_user.password.$error"
                v-model="admin_user.password"
              >
                <template v-slot:label>
                  Password <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.password" >{{errors.password[0]}}</span>
              <span class="text-danger"  v-if="!edit && !$v.admin_user.password.minLength">
                Password must have at least 6 characters.
              </span>
            </v-col>

            <v-col cols="12" md="6" v-if="! this.edit">
              <v-text-field 
                outlined 
                dense 
                type="password"
                :error="$v.admin_user.password_confirmation.$error"
                v-model="admin_user.password_confirmation"
              >
                <template v-slot:label>
                  Confirm password <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.password_confirmation" >{{errors.password_confirmation[0]}}</span>
              <span
                class="text-danger"
                v-if="!edit && !$v.admin_user.password_confirmation.sameAsPassword"
              >
                Password Must Match
              </span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                label="Mobile" 
                outlined 
                dense 
                :error="$v.admin_user.mobile.$error"
                v-model="admin_user.mobile"
              ></v-text-field>
              <span class="text-danger" v-if="errors.mobile" >{{errors.mobile[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.mobile.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                label="Phone" 
                outlined 
                dense 
                :error="$v.admin_user.phone.$error"
                v-model="admin_user.phone"
              ></v-text-field>
              <span class="text-danger" v-if="errors.phone" >{{errors.phone[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.phone.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field 
                outlined 
                dense 
                :error="$v.admin_user.address.$error"
                v-model="admin_user.address"
              >
                <template v-slot:label>
                  Address <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.address" >{{errors.address[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.address.$error">This information is required</span>
            </v-col>            

            <!-- <v-col cols="12" md="4">
              <v-select
                label="User Type *" 
                outlined 
                :items="user_type"
                item-text="name"
                item-value="value"
                dense 
                :error="$v.admin_user.user_type.$error"
                v-model="admin_user.user_type"
              ></v-select>
              <span class="text-danger" v-if="errors.user_type" >{{errors.user_type[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.user_type.$error">This information is required</span>
            </v-col> -->

            <v-col cols="12" md="6" v-if="currentUser.access_type == 'federal'">
              <v-select
                outlined 
                :items="access_types"
                item-text="name"
                item-value="value"
                @change="handleAccessType"
                dense 
                :error="$v.admin_user.access_type.$error"
                v-model="admin_user.access_type"
              >
                <template v-slot:label>
                  Access type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.access_type" >{{errors.access_type[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.access_type.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6" v-if="currentUser.access_type == 'federal'">
              <v-select :items="admin_user_types" item-value="value" item-text="text"
                  v-model="admin_user.user_type"
                  :error="$v.admin_user.user_type.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Access level <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.user_type" >{{errors.user_type[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.user_type.$error">This information is required</span>
            </v-col>

            <v-col cols="6" v-if="score_flag && currentUser.access_type == 'federal'">
              <v-select 
                outlined 
                dense 
                v-model="admin_user.score_id"
                :error="$v.admin_user.score_id.$error"
                :items="scores"
                item-text="name"
                item-value="id"
                clearable
              >
                <template v-slot:label>
                  SCORE <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.score_id" >{{errors.score_id[0]}}</span>
              <span class="text-danger" v-if="$v.admin_user.score_id.$error">This information is required</span>
            </v-col>



          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12"  v-if="!edit">
              <v-checkbox v-model="admin_user.send_email">
                <template v-slot:label>
                  <span class="text-left font-weight-medium">
                    Email password upon save
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" v-if="admin_user.send_email && !edit">
              <v-combobox
                v-model="admin_user.group_email"    
                prepend-inner-icon="mdi-send"
                hide-selected outlined dense deletable-chips
                multiple  
                small-chips
              >
                <template v-slot:label>
                  Add email/s to send password notification <span class="text-danger">*</span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Press <kbd>enter</kbd> to create a new Email
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <span class="text-danger" v-if="$v.admin_user.group_email.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="3" v-if="edit">
              <v-switch
                  v-model="admin_user.is_mfa_enabled"
                  :error="$v.admin_user.is_mfa_enabled.$error"
                  label="MFA - App"
                  :disabled="disableMfa"
              ></v-switch>
              <span class="text-danger" v-if="$v.admin_user.is_mfa_enabled.$error">This information is required</span>
              <span class="text-danger" v-if="errors.is_mfa_enabled">{{ errors.is_mfa_enabled[0] }}</span>
            </v-col>

            <v-col cols="12" md="3" v-if="edit">
              <v-switch
                  v-model="admin_user.is_email_authentication_enabled"
                  :error="$v.admin_user.is_email_authentication_enabled.$error"
                  label="MFA - Email"
                  :disabled="disableEmailAuthentication"
              ></v-switch>
              <span class="text-danger" v-if="$v.admin_user.is_email_authentication_enabled.$error">This information is required</span>
              <span class="text-danger" v-if="errors.is_email_authentication_enabled">{{ errors.is_email_authentication_enabled[0] }}</span>
            </v-col>

            <v-col cols="12" md="3">
              <v-switch
                  v-model="admin_user.is_active"
                  :error="$v.admin_user.is_active.$error"
                  :label="admin_user.is_active ? 'Active': 'Inactive'"
              ></v-switch>
              <span class="text-danger" v-if="$v.admin_user.is_active.$error">This information is required</span>
              <span class="text-danger" v-if="errors.is_active">{{ errors.is_active[0] }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            dark
            x-large
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>


      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {email, minLength, required, requiredIf, sameAs} from "vuelidate/lib/validators";
import AdminUserService from "@/services/admin-user/AdminUserService";
import ScoreService from "@/services/score/score/ScoreService";
import SettingService from "@/services/setting/SettingService";
const setting=new SettingService();
const admin_user = new AdminUserService();
const score = new ScoreService();

export default {
  validations(){
    return {admin_user : this.adminUserRules};
  },
  computed:{
    disableEmailAuthentication(){
      if(this.edit){
        if(this.admin_user.is_email_authentication_enabled == 1){
          return false;
        }
        if(this.admin_user.is_email_authentication_enabled == 0){
          return true;
        }
      }
      return true;
    },
    disableMfa(){
      if(this.edit){
        if(this.admin_user.is_mfa_enabled == 1){
          return false;
        }
        if(this.admin_user.is_mfa_enabled == 0){
          return true;
        }
      }
      return true;
    },
    adminUserRules(){
      let rules = {};

      if(! this.edit){
        rules = {
          first_name: {required},
          middle_name: {},
          last_name: {required},
          password: {required, minLength: minLength(6)},
          password_confirmation: {required, sameAsPassword: sameAs('password')},
          mobile: {},
          phone: {},
          username: {required},
          email: {required, email},
          address: {required},
          access_type: {required},
          user_type: {required},
          score_id: {
            required: requiredIf(function (nestedModel){
              return this.admin_user.access_type == 'score';
            })
          },
          group_email: {
            required: requiredIf(function (nestedModel){
              return this.admin_user.send_email;
            })
          },
          is_mfa_enabled: {required},
          is_email_authentication_enabled: {required},
          is_active: {required}
        }
      }
      if(this.edit){
        rules = {
          first_name: {required},
          middle_name: {},
          last_name: {required},
          password: {},
          password_confirmation: {},
          mobile: {},
          phone: {},
          username: {required},
          email: {required, email},
          address: {required},
          access_type: {required},
          user_type: {},
          score_id: {},
          is_mfa_enabled: {required},
          is_email_authentication_enabled: {required},
          is_active: {required}
        }
      }
      return rules;
    } 
  },
  data() {
    return {
      title: "",
        edit: false,
        errors: [],
        dialog: false,
        loading: false,
        currentUser: {},
        scores: [],
        emails: [],
        access_types: [
          {
            'name' : 'Federal',
            'value' : 'federal'
          },
          {
            'name' : 'SCORE',
            'value' : 'score'
          },
        ],
        user_type: [
          {
            'name' : 'Superadmin',
            'value' : 'superadmin'
          },
          {
            'name' : 'State',
            'value' : 'state'
          },
        ],
        score_flag: false,
        admin_user: {
          first_name : '',
          middle_name : '',
          last_name : '',
          password: '',
          password_confirmation : '',
          mobile : '',
          phone : '',
          username : '',
          email : '',
          address : '',
          access_type : '',
          user_type : '',
          score_id : null,
          is_mfa_enabled : 0,
          is_email_authentication_enabled : 0,
          is_active : 1,
          send_email: 0,
          group_email: [],

        },
      admin_user_types:[],
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createAdminUser() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.admin_user.access_type = '';
      this.title = "Add";
      if(this.currentUser.access_type == 'score'){
        this.admin_user.access_type = 'score';
        this.admin_user.user_type = 'score';
        this.admin_user.score_id = this.currentUser.score_id;
      }
    },
    editAdminUser(item) {
      this.openDialog();
      this.edit = true;
      this.admin_user = item;
      if(this.admin_user.score_id){
        this.score_flag = true;
      }
      this.title = "Edit";
      if(this.currentUser.access_type == 'score'){
        this.admin_user.access_type = 'score';
        this.admin_user.score_id = this.currentUser.score_id;
      }
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } 
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    handleAccessType(){
      if(this.admin_user.access_type == 'score'){
        this.score_flag = true;
        this.admin_user.score_id = null;
      }else{
        this.score_flag = false;
        this.admin_user.score_id = null;
      }
    },
    getAllScores(){
      score
      .all()
      .then((response) => {
        this.scores = response.data.scores;
      })
      .catch((err) => {

      });
    },
    _create: function () {
      this.loading = true;
      admin_user
      .create(this.admin_user)
      .then(response => {
        this.$snotify.success("System user added");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    update: function () {
      this.loading = true;
      this.admin_user.password = null;
      this.admin_user.password_confirmation = null;
      admin_user
      .update(this.admin_user.id, this.admin_user)
      .then(response => {
        this.$snotify.success("System user profile updated");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    resetForm() {
      this.admin_user = {
      first_name : '',
      middle_name : '',
      last_name : '',
      password: '',
      password_confirmation : '',
      mobile : '',
      phone : '',
      username : '',
      email : '',
      address : '',
      access_type : null,
      user_type : '',
      score_id : null,
      is_mfa_enabled : 0,
      is_email_authentication_enabled : 0,
      is_active : 1,
      send_email: 0,
      group_email: []
    },
      this.score_flag = false;
      this.errors=[];
      this.$v.$reset();
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    getAdminUserTypeOption(){
      setting
          .getAdminUserTypeOption()
          .then(response => {
            for(let key in response.data.admin_type_option){
              this.admin_user_types.push({value:key, text:response.data.admin_type_option[key]})
            }
          })
    },
  },
  mounted(){
    this.getAllScores();
    this.getCurrentUser();
    this.getAdminUserTypeOption();
  }
}
</script>