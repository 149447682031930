<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                Admin access
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Admin access
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createAdminUser()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;">
                    <i class="fa fa-plus"></i>
                    Add admin user
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="12" md="3">
                  <v-text-field 
                    label="Search by name or email"
                    v-model="search.name" 
                    outlined 
                    dense
                    v-on:keyup.enter="searchAdminUsers()"
                    @input="search.name = $event !== null ? $event : ''" 
                    clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="3" v-if="currentUser.access_type != 'score'">
                  <v-select
                      label="SCORE"
                      v-model="search.score_id"
                      :items="scores"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      v-on:keyup.enter="searchAdminUsers()"
                      @input="search.score_id = $event !== null ? $event : ''"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <v-select 
                    :items="search_statuses" 
                    v-model="search.status" 
                    label="Status" 
                    item-text="name" 
                    item-value="value" 
                    v-on:keyup.enter="searchAdminUsers()"
                    @input="search.status = $event !== null ? $event : ''" 
                    outlined 
                    clearable
                    dense
                  ></v-select>
                </v-col>
                
                <v-col cols="12" sm="12" :md="currentUser.access_type != 'score' ? 3 : 6">
                  <v-btn 
                    @click="searchAdminUsers()" 
                    :loading="loading" 
                    class="btn btn-primary w-35 float-right"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
        
            <div class="table-responsive">
              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">User name</th>
                  <th class="px-3">Email</th>
                  <th class="px-3">Access</th>
                  <th class="px-3">SCORE</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in admin_users" :key="index">
                    <td class="px-2">
                      <span>
                        {{ item.full_name }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span>
                        {{ item.email }}
                      </span>
                    </td>
                    <td class="px-2">
                      <div>
                        Access level:
                        <span class="badge badge-success text-lg">
                          {{ item.access_type_text }}
                        </span>
                      </div>
                      <br>
                      <div>
                        User type:
                        <span  class="badge badge-success text-lg">
                           {{ item.user_type_text }}
                        </span>
                      </div>
                    </td>
                    <td class="px-2">
                      <span>
                        {{ item.score_purchase_prefix ? item.score_purchase_prefix : 'NA'}}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="badge"
                            v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                      >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editAdminUser(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="changePassword(item)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-key"></i>
                                </span>
                                <span class="navi-text">Change password</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteAdminUser(item.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="admin_users.length == 0">
                      <td colspan="5" class="text-center">
                          No items found
                      </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="admin_users.length > 0"
                  class="pull-right mt-7"
                  @input="getAdminUsers"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>

      <create-and-update ref="create-and-update" @refresh="getAdminUsers"></create-and-update>

      <password-update ref="change-password" @refresh="getAdminUsers"></password-update>

    </div>
  </v-app>    
</template>

<script>
import AdminUserService from "@/services/admin-user/AdminUserService";
import CreateAndUpdate from "./CreateAndUpdate";
import PasswordUpdate from "./PasswordUpdate";
import ScoreService from "@/services/score/score/ScoreService";

const admin_user = new AdminUserService();
const score = new ScoreService();

export default{
  components: {
    CreateAndUpdate, PasswordUpdate
  },
  data(){
      return{
          total: null,
          perPage: null,
          page: null,
          admin_users: [],
          scores: [],
          currentUser: {},
          loading: true,
          search:{
              name: '',
              score_id: '',
              status: ''
          },
          search_statuses: [
            {name: 'Active', value: 1},
            {name: 'Inactive', value: 0},
          ],
      }
  },
  methods:{
      searchAdminUsers(){
        this.getAdminUsers();
      },
      getCurrentUser() {
        this.currentUser =  this.$store.getters.currentUser;
      },
      getAllScore(){
        score
            .all()
            .then(response => {
              this.scores=response.data.scores;
            })
            .catch((err) => {});
      },
      getAdminUsers(){
          admin_user
          .paginate(this.search, this.page)
          .then(response => {
            this.admin_users=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
          })
          .finally(() => {
            this.loading = false;
          });
      },
      createAdminUser(){
          this.$refs['create-and-update'].createAdminUser();
      },
      editAdminUser(item) {
        this.$refs['create-and-update'].editAdminUser(item);
      },
      changePassword(item){
        this.$refs['change-password'].changePassword(item);
      },
      deleteAdminUser(userId){
        this.$confirm({
          message: `Are you sure? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              admin_user
              .delete(userId)
              .then((response) => {
                this.getAdminUsers();
                this.$snotify.success("System user deleted");
              })
              .catch((err) => {
              });
            }
          },
        });
      },
  },
  mounted(){
    this.getAdminUsers();
    this.getAllScore();
    this.getCurrentUser();
  }
}
</script>
