<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span> Change password </span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="">
            <v-col cols="6">
              Auto generate password
              <v-switch
                  v-model="autoGenerate"
                  :label="autoGenerate ? 'Yes': 'No'"
                  @change="togglePasswordField"
              ></v-switch>  
            </v-col>  
            <v-col v-if="! autoGenerate" cols="12" md="12"> 
              <v-text-field
                v-model="admin_user.password"
                :error="$v.admin_user.password.$error"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                outlined
                dense
              >
                <template v-slot:label>
                  Password <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.password" >{{errors.password[0]}}</span>
              <span class="text-danger"  v-if="!$v.admin_user.password.minLength">
                Password must have at least 6 characters.
              </span>
            </v-col>

            <v-col v-if="!autoGenerate" cols="12" md="12">
              <v-text-field
                :error="$v.admin_user.password_confirmation.$error"
                v-model="admin_user.password_confirmation"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                outlined
                dense
              >
                <template v-slot:label>
                  Confirm password <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.password_confirmation" >{{errors.password_confirmation[0]}}</span>
              <span
                class="text-danger"
                v-if="!$v.admin_user.password_confirmation.sameAsPassword"
              >
                Passwords not matching yet
              </span>
            </v-col>            

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="cancel-btn"
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>

        <v-btn
            color="btn btn-primary"
            dark
            x-large
            @click="updatePassword()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, requiredIf, minLength, sameAs} from "vuelidate/lib/validators";
import AdminUserService from "@/services/admin-user/AdminUserService";

const admin_user = new AdminUserService();

export default {
  validations:{
    admin_user : {
        password: {
          minLength: minLength(6),  
          required: requiredIf(function (){
            return this.autoGenerate == 0;
          }),
        },
        password_confirmation: {sameAsPassword: sameAs('password')},
    }
  },
  
  data() {
    return {
        errors: [],
        dialog: false,
        loading: false,
        showPassword: false,
        showConfirmPassword: false,
        autoGenerate: 1,
        admin_user: {
          password: '',
          password_confirmation : '',
        },
        admin_user_id: null,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    changePassword(item){
        this.resetForm();
        this.openDialog();
        this.admin_user_id = item.id;
    },
    togglePasswordField(){
      this.admin_user.password = null;
      this.admin_user.password_confirmation = null;
    },
    updatePassword() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } 
      else {
        this.loading = true;
        this.admin_user['form_type'] = "update-password";
        if(this.autoGenerate){
          this.admin_user.autoGenerate = 1;
        }else{
          this.admin_user.autoGenerate = 0;
        }
        admin_user
        .updatePassword(this.admin_user_id, this.admin_user)
        .then(response => {
            this.$snotify.success("Password changed successfully, please check your registered mail.");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
        })
        .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
        });
      }
    },
    
    resetForm() {
      this.admin_user = {
        password: '',
        password_confirmation : '',
      };
      this.errors=[];
      this.$v.$reset();
    },
  }
}
</script>